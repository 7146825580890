import { useCallback, useState } from "react";
import { CartItem } from "@ludens-reklame/rubics-v3-sdk/cart/types";
import { useCart } from "@ludens-reklame/rubics-v3-react/cart/hooks";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { currencyFormatter } from "../../client-utils/formatters.js";
import { arrow, trash } from "../../icons/svg.js";
import { Busy } from "../../internal-components/busy/busy.js";

const Cart: React.FC = () => {
  const { cart, networking, addDiscountCode, removeItems } = useCart();
  const [discountCode, setDiscountCode] = useState<string>("");
  const [addingDiscount, setAddingDiscount] = useState<boolean>(false);

  const handleDiscountActivation = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setAddingDiscount(true);
      await addDiscountCode(discountCode);
      setDiscountCode("");
      setAddingDiscount(false);
    },
    [discountCode, addDiscountCode]
  );

  return (
    <div className="cart mw hs vs-xl">
      <h1>Handlekurv</h1>
      <Busy busy={networking}>
        <div className="mobile">
          <div className="discount">
            <form onSubmit={handleDiscountActivation}>
              <div className="input-field">
                <label htmlFor="discount-code b2">Rabattkode:</label>
                <input
                  id="discount-code"
                  type="text"
                  placeholder="Rabattkode"
                  disabled={networking}
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  className="b1"
                />
              </div>
              <button className="button" type="submit" disabled={networking}>
                {networking && addingDiscount
                  ? "Aktiverer..."
                  : "Bruk rabattkode"}
              </button>
            </form>
          </div>
          <div className="content">
            <h2 className="h6">Produkter</h2>
            {cart.items.map((item) => {
              return (
                <ProductRow
                  key={item._id}
                  item={item}
                  removeItems={removeItems}
                  mobile
                />
              );
            })}
            <button className="button tertiary update-button">
              Oppdater handlekurv
            </button>
          </div>
          <div className="prices">
            <ul>
              <li>
                <p className="h6">Delsum:</p>
                <p>{currencyFormatter.format(cart.total - cart.totalTax)}</p>
              </li>
              <li>
                <p className="h6">MVA:</p>
                <p>{cart.totalTaxText}</p>
              </li>
              <li>
                <p className="h6">Totalt:</p>
                <p>{cart.totalText}</p>
              </li>
            </ul>
            <a href="/utsjekk" className="button secondary">
              Fortsett til kassen {arrow}
            </a>
          </div>
        </div>
        <div className="desktop">
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Produkt</th>
                  <th>Pris</th>
                  <th>Antall</th>
                  <th>Delsum</th>
                </tr>
              </thead>
              <tbody>
                {cart.items.map((item) => {
                  return (
                    <ProductRow
                      key={item._id}
                      item={item}
                      removeItems={removeItems}
                    />
                  );
                })}
              </tbody>
            </table>
            <div className="discount">
              <div className="section">
                <form onSubmit={handleDiscountActivation}>
                  <div className="input-field">
                    <label htmlFor="discount-code">Rabattkode:</label>
                    <input
                      id="discount-code"
                      type="text"
                      placeholder="Rabattkode"
                      disabled={networking}
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                      className="b1"
                    />
                  </div>
                  <button
                    className="button"
                    type="submit"
                    disabled={networking}
                  >
                    {networking && addingDiscount
                      ? "Aktiverer..."
                      : "Bruk rabattkode"}
                  </button>
                </form>
              </div>
              <div className="section">
                <button className="button tertiary">Oppdater handlekurv</button>
              </div>
            </div>
            <div className="prices">
              <ul>
                <li>
                  <p className="h6">Delsum:</p>
                  <p>{currencyFormatter.format(cart.total - cart.totalTax)}</p>
                </li>
                <li>
                  <p className="h6">MVA:</p>
                  <p>{cart.totalTaxText}</p>
                </li>
                <li>
                  <p className="h6">Totalt:</p>
                  <p>{cart.totalText}</p>
                </li>
              </ul>
              <a href="/utsjekk" className="button secondary">
                Fortsett til kassen {arrow}
              </a>
            </div>
          </div>
        </div>
      </Busy>
    </div>
  );
};

interface ProductRowProps {
  item: CartItem;
  removeItems: (ids: string[]) => void;
  mobile?: boolean;
}

export const ProductRow: React.FC<ProductRowProps> = ({
  item,
  removeItems,
  mobile = false,
}) => {
  if (mobile) {
    return (
      <div key={item._id} className="product">
        <h3 className="b2">{item.product.name}</h3>
        <div className="actions">
          <div className="quantity">
            <input type="number" step="1" min={1} value={item.quantity} />
          </div>
          <button
            className="trash"
            onClick={() => removeItems([item._id])}
            aria-label={`Fjern ${item.product.name} fra handlekurven`}
          >
            {trash}
          </button>
        </div>
        <div className="compare">
          <p className={item.product.compareAtPrice ? "inactive" : undefined}>
            {item.product.priceText}
          </p>
          {item.product.compareAtPrice && (
            <p className="compare-at-price">
              {item.product.compareAtPriceText}
            </p>
          )}
        </div>
        <div className="total">
          <p>Delsum:</p>
          <p>{item.totalText}</p>
        </div>
      </div>
    );
  }

  return (
    <tr key={item._id}>
      <td>
        <button className="trash" onClick={() => removeItems([item._id])}>
          {trash}
        </button>
      </td>
      <td>
        {item.product.primaryImage && (
          <img
            src={transformImageUrl(item.product.primaryImage, {
              width: 144,
              fit: "contain",
            })}
            alt={item.product.name}
          />
        )}
      </td>
      <td>{item.product.name}</td>
      <td className="unit-price">
        <span className={item.product.compareAtPrice ? "inactive" : undefined}>
          {item.product.priceText}
        </span>
        {item.product.compareAtPrice && (
          <span className="compare-at-price">
            {item.product.compareAtPriceText}
          </span>
        )}
      </td>
      <td>
        <input type="number" step="1" min={1} value={item.quantity} />
      </td>
      <td>{item.totalText}</td>
    </tr>
  );
};

export default Cart;
